var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settingsWrapper p-3" }, [
    _c("div", { staticClass: "d-flex" }, [
      _c("aside", { staticClass: "p-3", staticStyle: { width: "20%" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "list-group list-group-flush settings-menu",
            staticStyle: {},
          },
          [
            _c(
              "router-link",
              {
                staticClass: "list-group-item list-group-item-action",
                attrs: { to: { name: "PercentageSettings" } },
              },
              [_vm._v("\n          Prozentsätze\n        ")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "list-group-item list-group-item-action",
                attrs: { to: { name: "CategorySettings" } },
              },
              [_vm._v("Versicherungs-Kategorien\n        ")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "list-group-item list-group-item-action",
                attrs: { to: { name: "InsuranceSettings" } },
              },
              [_vm._v("Versicherungs Sparten\n        ")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "list-group-item list-group-item-action",
                attrs: { to: { name: "TextSettings" } },
              },
              [_vm._v("Textbausteine\n        ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "px-3 py-4", staticStyle: { width: "80%" } },
        [_c("router-view")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h5", { attrs: { id: "page-heading" } }, [
      _c("span", { attrs: { id: "answer-heading" } }, [
        _vm._v("Einstellungen"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }